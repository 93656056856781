.row-bg {
    flex: 1;
    justify-content: center;
    height: 25px;
  }

  img.row-bg {
    display: block;
    position: absolute;
    margin-left: 124px;
    margin-top: -10px;
  }
  .row-label {
    font-family: 'Inter-Regular', sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -0.15px;
    text-align: left;
    color: #0052ca;
    width: 200px;
    cursor: pointer;
  }
  .row-watts {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: -0.15px;
    text-align: left;
    color: #0052ca;
  }
  .row-column {
    flex: 1;
    flex-direction: column;
    border-left-color: #d1d3d4;
    border-left-width: 1px;
    border-right-color: #d1d3d4;
    border-right-width: 1px;
    padding-left: 9.3px;
    margin-bottom: 10.3px;
    width: 186px;
  }
  .row-row {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -3px;
  }
  .row-halfBox {
    width: 100%;
  }
  .row-checkIcon {
    width: 12px;
    height: 12px;
    position: relative;
    top: 3px;
    background-size: contain;
  }